import styled from "styled-components";
import { fadeInLeft } from "../extras/Keyframes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  width: 100%;
  min-height: 100vh;
  background: rgb(0, 112, 153);
  background: radial-gradient(
    circle,
    rgba(0, 112, 153, 1) 0%,
    rgba(0, 168, 230, 1) 100%
  );
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 10px;
  width: 100vw;
  @media (max-width: 813px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  align-items: center;
  @media (max-width: 813px) {
    width: 100vw;
  }
`;

/*
  background: rgb(0, 131, 179);
  background: radial-gradient(
    circle,
    rgba(0, 131, 179, 1) 0%,
    rgba(0, 131, 179, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
 */

const Title = styled.p`
  text-align: left;
  font-size: 2.5em;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 3px black;
`;

const Subtitle = styled.p`
  text-align: CENTER;
  font-size: 1.5em;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 1px black;
`;

const Description = styled.p`
  color: white;
  font-size: 1em;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  animation: ${fadeInLeft}3.5s;
  @media (max-width: 813px) {
    padding: 13px;
  }
`;

const DescriptionImportant = styled.p`
  color: white;
  font-size: 1.2em;
  margin-left: 20px;
  margin-right: 20px;
  text-align: justify;
  animation: ${fadeInLeft}3.5s;
  @media (max-width: 813px) {
    padding: 13px;
  }
`;

const Line = styled.hr`
  width: 80%;
  height: 1px;
  border: none;
  background-color: lightgray;
`;

export default {
  Title,
  Container,
  Description,
  Line,
  DescriptionImportant,
  Row,
  Column,
  Subtitle,
};
