import React from "react";
import { Gallery } from "@angimenez/react-gallery";
import Section from "../components/Section";

const IMAGES = [
  {
    url: "/images/gallery/EstuapPrincipal.png",
    description: "App Estuap",
  },
  {
    url: "/images/gallery/GestionAlarmas.png",
    description: "Programa de gestión empresa de alarmas",
  },
  {
    url: "/images/gallery/EstuapMapas.png",
    description: "Estuap sección mapas",
  },
  {
    url: "/images/gallery/EstuapStreaming.png",
    description: "Plataforma web de streaming de Estuap",
  },
  {
    url: "/images/gallery/EstuapSudoku.png",
    description: "Estuap sección juegos",
  },
  {
    url: "/images/gallery/Domotica.png",
    description: "App de control de dispositivos (domótica)",
  },
  {
    url: "/images/gallery/GestionGeneral.png",
    description: "Programa de gestión de comercio genérico",
  },
  {
    url: "/images/gallery/SevenApp.png",
    description: "App de alertas de seguridad (empresa de alarmas)",
  },
];

const Products = ({ id = "section-images" }) => {
  return (
    <Section.Container id={id}>
      <Section.Line />
      <Section.Title>Algunos proyectos...</Section.Title>
      <Gallery images={IMAGES} />
    </Section.Container>
  );
};

export default Products;
