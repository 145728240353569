import { keyframes } from "styled-components";


const fadeInUp = keyframes` {
  0% {
     opacity: 0;
     transform: translateY(-150px);
  }
  50% {
     opacity: 1;
     transform: translateY(20px);
  
} 
100% {
     opacity: 1;
     transform: translateY(0px);
  
} 

// 

`; 
const fadeInDown = keyframes` {
  0% {
     opacity: 0;
     transform: translateY(150px);
  }
  50% {
     opacity: 1;
     transform: translateY(-20px);
  
} 
100% {
     opacity: 1;
     transform: translateY(0px);
  
} 
`; 

//


const fadeInLeft = keyframes` {
  0% {
     opacity: 0;
     transform: translateX(-950px);
  }
  50% {
     opacity: 1;
     transform: translateX(40px);
  
} 
100% {
     opacity: 1;
     transform: translateX(0px);
  
} 

//

`; 
const fadeInRight = keyframes` {
  0% {
     opacity: 0;
     transform: translateX(950px);
  }
  50% {
     opacity: 1;
     transform: translateX(-40px);
  
} 
100% {
     opacity: 1;
     transform: translateX(0px);
  
} 
`; 

//

const fadeIn = keyframes` {
  0% {
     opacity: 0;
  }
  
} 
100% {
     opacity: 1;
  
} 
`; 

//

const Zoom = keyframes` {
  0% {
     opacity: 0;
     transform: scale(1.5);
  }
100% {
     opacity: 1;
     transform: scale(1);
  
} 

//

`; 
const ZoomIn = keyframes` {
  0% {
     opacity: 0;
     transform: scale(1.2);
  }
  50% {
     opacity: 1;
     transform: scale(.9);
  }

100% {
     opacity: 1;
     transform: scale(1);
  
} 
`; 



export {fadeInUp, fadeIn, fadeInLeft, fadeInRight, fadeInDown, ZoomIn, Zoom};