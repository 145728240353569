import React from "react";
import styled from "styled-components";
import { navigate } from "@angimenez/react-menu";
// import Menu from "./Menu";
import { fadeInLeft, fadeInRight, ZoomIn } from "../extras/Keyframes";
import Section from "../components/Section";

const Head = ({ id = "section-main" }) => {
  const handleClick = (section) => () => {
    navigate(section, 400);
  };
  return (
    <>
      <Section.Container id={id}>
        <Image src="/images/profile.jpeg" alt="my profile" />
        <Text>Nicolás Giménez</Text>
        <Subtitle> Desarrollador </Subtitle>
        <Contactar onClick={handleClick("section-contact")}>
          Contactar
        </Contactar>
      </Section.Container>
    </>
  );
};

const Image = styled.img`
  width: 350px;
  height: 350px;
  border-radius: 50%;
  object-fit: cover;
  border: solid 2px white;
  max-width: 90vw;
  max-height: 90vw;
`;

const Contactar = styled.button`
  margin-top: 20px;
  width: 200px;
  cursor: pointer;
  outline: none;
  color: white;
  background: #00394d;
  height: 50px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1em;
  border: solid 2px #66d9ff;
  border-radius: 50px;
  transition: all 0.5s;
  animation: ${ZoomIn} 3s;
  margin-bottom: 10px;
  :hover {
    transform: scale(1.05);
  }
`;

const Subtitle = styled.h3`
  font-size: 2em;
  margin: 0px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  animation: ${fadeInRight} 2.5s;
  text-shadow: 0 0 3px white;
  @media (max-width: 813px) {
    font-size: 1.7em;
  }
`;

const Text = styled.p`
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
  font-size: 4em;
  margin: 0px;
  color: white;
  margin-top: 20px;
  animation: ${fadeInLeft} 2.5s;
  text-shadow: 0 0 3px black;
  text-align: center;
  @media (max-width: 813px) {
    font-size: 3em;
  }
`;

export default Head;
