import React from "react";
import Section from "../components/Section";
import styled from "styled-components";

const Who = ({ id = "section-who" }) => {
  return (
    <Section.Container id={id}>
      <Section.Line />
      <Section.Title>¿Quién soy?</Section.Title>
      <Section.DescriptionImportant>
        Soy desarrollador fullstack: backend y frontend (aplicaciones
        móviles y páginas web).
        <br />
        Poseo experiencia realizando sistemas de gestión para empresas. <br />
        Tengo conocimientos en diversos lenguajes de programación y de bases de
        datos, pero me he especializado en el desarrollo de webapps utilizando
        ReactJS y NodeJS como backend. Experiencia desarrollando en
        React-Native, lo que me permite crear apps nativas utilizando mis
        conocimientos de ReactJS. <br />
        En los últimos tiempos he incursionado en el mundo de YouTube,
        emprendiendo una nueva etapa, en la que he decidio compartir mi
        experiencia como desarrollador y emprendedor, para ayudar a la
        comunidad, al igual que me han ayudado otras personas en mis inicios.
        <br />
        Por último, quiero decir que soy un apasionado por las nuevas
        tecnologías, lo que me permite estar al día todo el tiempo.
      </Section.DescriptionImportant>
      <Section.Row>
        <Section.Column>
          <SectionImage>
            <Laptop src="/images/notebook.png" alt="Notebook" />
            <Image src="/images/logov1Grande.jpg" alt="Notebook" />
          </SectionImage>
        </Section.Column>
        <Section.Column>
          <Section.Subtitle>REDES SOCIALES</Section.Subtitle>
          <Social
            a
            href="https://bit.ly/363Hcrh"
            backgroundHover="#b32400"
            target="_blank"
          >
            <span role="img" aria-label="Right">
              👉
            </span>
            &emsp;Suscripción YouTube&emsp;
            <span role="img" aria-label="Left">
              👈
            </span>
          </Social>
          <Social
            a
            href="https://www.instagram.com/nico.gimenez6/"
            backgroundHover="#cc0044"
            target="_blank"
          >
            Instagram
          </Social>
          <Social
            a
            href="https://www.facebook.com/programandoconkiki"
            backgroundHover="#008ae6"
            target="_blank"
          >
            Facebook
          </Social>
          <Social
            a
            href="https://twitter.com/Nigim16"
            backgroundHover="#00ccff"
            target="_blank"
          >
            Twitter
          </Social>
          <Social
            a
            href="https://www.linkedin.com/in/nicolás-giménez-bratschi-b61549a5"
            backgroundHover="#0039e6"
            target="_blank"
          >
            Linkedin
          </Social>
        </Section.Column>
      </Section.Row>
    </Section.Container>
  );
};

const Laptop = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 50vw;
  @media (max-width: 813px) {
    max-width: 100vw;
  }
`;

const Image = styled.img`
  height: 78%;
  left: 50%;
  position: absolute;
  top: 2%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 4px;
`;

const SectionImage = styled.div`
  position: relative;
`;

const Social = styled.a`
  border-radius: 4px;
  background-color: transparent;
  color: white;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: all 1s;
  padding: 1em 1.5em;
  :hover {
    transform: scale(1.5);
    background-color: ${({ backgroundHover = "white" }) => backgroundHover};
  }
`;

export default Who;
