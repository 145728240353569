import React from "react";
import styled from "styled-components";
import { ReactComponent as Fac } from "../assets/icons/face.svg";
import { ReactComponent as What } from "../assets/icons/whatsapp.svg";
import { ReactComponent as Ins } from "../assets/icons/insta.svg";
import { ReactComponent as Con } from "../assets/icons/contact.svg";

const RedSocialF = styled.a`
  margin: 5px 20px 5px 5px;
  cursor: pointer;
`;
const Numero = styled.div`
  margin: 5px 20px 5px 5px;
  cursor: pointer;
`;
const RedSocialI = styled.a`
  margin-right: 20px;
  cursor: pointer;
`;
const Contact = ({ href = "" }) => (
  <RedSocialF href={href}>
    <Con />
  </RedSocialF>
);
const Face = () => (
  <RedSocialI href="https://www.facebook.com/Nigim16" target="_blank">
    <Fac />
  </RedSocialI>
);
const Insta = () => (
  <RedSocialF a href="https://www.instagram.com/nico.gimenez6" target="_blank">
    <Ins />
  </RedSocialF>
);
const Number = () => (
  <Numero>
    <What />
  </Numero>
);

export {
  Face,
  Insta,
  Contact,
  Number
};
