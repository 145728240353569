import React from "react";
import styled from "styled-components";
import Head from "./sections/Head";
import Products from "./sections/Products";
import Who from "./sections/Who";
import Footer from "./sections/Footer";
import Menu from "./sections/Menu";
//import WorkDone from "./sections/WorkDone";
/*/Users/nigim/angimenez/customComponents/react-gallery/
    "@angimenez/react-gallery": "git+https://github.com/angimenez/react-gallery.git",

      <Who id="section-who" />
      <Products id="section-products" />
      <WorkDone id="section-work" />
*/
function App() {
  return (
    <>
      <Menu />
      <Container>
        <Head id="section-head" />
        <Who id="section-who" />
        <Products id="section-products" />
        <Footer id="section-contact" />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export default App;
