import React from "react";
import { MenuContainer, MenuItem, navigate } from "@angimenez/react-menu";
import styled from "styled-components";

const Menu = () => {
  const handleClick = (section) => () => {
    navigate(section, 400);
  };
  return (
    <MenuContainer color="#002533">
      <MenuItem onClick={handleClick("section-head")}>Inicio</MenuItem>
      <MenuItem onClick={handleClick("section-who")}>¿Quién soy?</MenuItem>
      <MenuItem onClick={handleClick("section-products")}>
        Algunos Proyectos
      </MenuItem>
      <MenuItem onClick={handleClick("section-contact")}>Contacto</MenuItem>
      <LinkTo href="https://blog.nicolasgimenez.com" target="_blank">VISTA MI BLOG!</LinkTo>
    </MenuContainer>
  );
};

const LinkTo = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 60px;
  margin: 5px 20px;
  padding: 10px;
  font-weight: normal;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

export default Menu;
