import React from "react";
import styled from "styled-components";
import { Face, Insta, Contact, Number } from "../sections/Icons";

const Footer = ({ id = "section-contact" }) => {
  return (
    <Container id={id}>
      <Row>
        <Contact href="mailto:info@nicolasgimenez.com" />
        <p>info@nicolasgimenez.com</p>
      </Row>
      <Row>
        <NavigationWpp
          a
          href="https://api.whatsapp.com/send?phone=59893953143&text=Hola%20Nicolas,%20quiero%20consultar%20por%20sus%20servicios."
          target="_blank"
        >
          <Number />
          <p> +598 93 953 143</p>
        </NavigationWpp>
      </Row>
      <Row>
        <Face />
        <Insta />
      </Row>
      <Row>
        <p>© 2020 Nicolás Giménez - Todos los derechos reservados</p>
      </Row>
    </Container>
  );
};

const NavigationWpp = styled.a`
  display: flex;
  color: white;
  align-items: center;
  text-decoration: none;
  flex-direction: row;
`;

const Container = styled.div`
  background-color: #002533;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 20px 5px 20px;
`;

export default Footer;
